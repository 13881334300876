<template>
    <div class="flex flex-row justify-center">
        <div
            ref="docsContent"
            class="w-full lg:w-2/3 pt-10 pb-64 px-6 docs-content">
            <h1>SDKs</h1>
            <p>Africa's Talking has official SDKs for different programming languages, these are:</p>
            <ul class="mt-4 list-none">
                <li
                    v-for="(sdk, idx) in officialSdks"
                    :key="idx">
                    <router-link :to="`/sdks#${sdk.name.toLowerCase()}`">{{sdk.name}}</router-link>
                </li>
            </ul>
            <div class="mt-10">
                <div
                    v-for="(sdk, idx) in officialSdks"
                    :key="idx">
                    <div class="flex items-center mb-3">
                        <h2 :id="sdk.name.toLowerCase()">{{ sdk.name }}</h2>
                        <a :href="sdk.packageManager" target="_blank">
                            <img alt="Latest Stable Version" :src="sdk.versionBadge" class="h-5 ml-2" />
                        </a>
                        <a :href="sdk.github" target="_blank">
                            <img alt="Github Stars" :src="sdk.starsBadge" class="h-5 ml-2" />
                        </a>
                    </div>
                    <VueMarkdown
                        :source="sdk.instructions"
                        @rendered="onMarkdownRendered" />
                    <hr v-if="idx !== officialSdks.length - 1" class="my-10">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown';

import hljs from 'highlight.js/lib/core';

import bash from 'highlight.js/lib/languages/bash';
import xml from 'highlight.js/lib/languages/xml';
import groovy from 'highlight.js/lib/languages/groovy';
import scala from 'highlight.js/lib/languages/scala';
import javascript from 'highlight.js/lib/languages/javascript';

import phpMd from './php.md';
import javaMd from './java.md';
import nodeMd from './node.md';
import pythonMd from './python.md';
import rubyMd from './ruby.md';

hljs.registerLanguage('bash', bash);
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('sc', scala);
hljs.registerLanguage('groovy', groovy);
hljs.registerLanguage('javascript', javascript);

// FIXME::ckarundu Explore escaping html instead
hljs.configure({ ignoreUnescapedHTML: true });

export default {
    name: 'Sdks',

    mounted() {
        this.highlightCodeSamples();
    },

    data() {
        return {
            officialSdks: [{
                name: 'PHP',
                packageManager: 'https://packagist.org/packages/africastalking/africastalking',
                versionBadge: 'https://img.shields.io/packagist/v/africastalking/africastalking',
                github: 'https://github.com/AfricasTalkingLtd/africastalking-php',
                starsBadge: 'https://img.shields.io/github/stars/AfricasTalkingLtd/africastalking-php?style=social',
                instructions: phpMd,
            }, {
                name: 'Java',
                packageManager: 'https://bintray.com/africastalking/java/com.africastalking',
                versionBadge: 'https://img.shields.io/jitpack/v/github/africastalkingltd/africastalking-java',
                github: 'https://github.com/AfricasTalkingLtd/africastalking-java',
                starsBadge: 'https://img.shields.io/github/stars/AfricasTalkingLtd/africastalking-java?style=social',
                instructions: javaMd,
            }, {
                name: 'Node',
                packageManager: 'https://www.npmjs.com/package/africastalking',
                versionBadge: 'https://img.shields.io/npm/v/africastalking',
                github: 'https://github.com/AfricasTalkingLtd',
                starsBadge: 'https://img.shields.io/github/stars/AfricasTalkingLtd/africastalking-node.js?style=social',
                instructions: nodeMd,
            }, {
                name: 'Python',
                packageManager: 'https://pypi.org/project/africastalking',
                versionBadge: 'https://img.shields.io/pypi/v/africastalking',
                github: 'https://github.com/AfricasTalkingLtd/africastalking-python',
                starsBadge: 'https://img.shields.io/github/stars/AfricasTalkingLtd/africastalking-python?style=social',
                instructions: pythonMd,
            }, {
                name: 'Ruby',
                packageManager: 'https://rubygems.org/gems/africastalking-ruby',
                versionBadge: 'https://img.shields.io/gem/v/africastalking-ruby',
                github: 'https://github.com/AfricasTalkingLtd/africastalking-ruby',
                starsBadge: 'https://img.shields.io/github/stars/AfricasTalkingLtd/africastalking-ruby?style=social',
                instructions: rubyMd,
            }],
        };
    },

    methods: {
        highlightCodeSamples() {
            this.$refs.docsContent.querySelectorAll('pre code').forEach((block) => {
                hljs.highlightElement(block);
            });
        },

        onMarkdownRendered() {
            this.$nextTick(() => {
                this.highlightCodeSamples();
            });
        },
    },

    components: {
        VueMarkdown,
    },
};
</script>

<style lang="postcss">
.docs-content {
    & code {
        font-size: .75rem;
        background-color: theme('colors.gray.100');
        border-radius: 3px;
    }

    & pre code {
        font-size: .875rem;
        padding: .75rem 1rem;
        margin: 1rem 0 1rem 0;
        background-color: theme('colors.codesample');
        color: theme('colors.gray.100');
    }

    & a {
        &:link,
        &:visited {
            color: theme('colors.orange.500');
        }

        &:hover,
        &:active {
            color: theme('colors.orange.600');
        }
    }

    & h1 {
        font-size: 1.875rem;
        font-weight: 600;
        line-height: 1;
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: theme("colors.gray.900");
    }

    & h2 {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1;
        margin-top: .5rem;
        margin-bottom: .5rem;
        color: theme("colors.gray.900");
    }

    & p {
        font-size: 1rem;
        color: theme("colors.gray.700");
    }
}
</style>
